import packageDetails from '../../package.json';

declare global {
  interface Window {
    dataLayer?: TrackingEvent[];
  }
}

// Max length of a label to include
const MAX_LENGTH = 100;

export type TrackingEvent = {
  event: string;
  eventCategory?: string | undefined;
  eventAction?: string | undefined;
  eventLabel?: string | undefined;
  vpvPath?: string;
  siteVersion?: string;
  isException?: boolean;
  downloadFileSize?: number | null;
  downloadFileName?: string;
  downloadFileText?: string;
  downloadFileUrl?: string;
  ariaLabelText?: string;
  linkElement?: string;
};

const trackEvent = (event: TrackingEvent): void => {
  if (event.eventLabel) {
    // eslint-disable-next-line no-param-reassign
    event.eventLabel = event.eventLabel.substring(0, MAX_LENGTH);
  }
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(event);
};

export const trackPageView = (path: string): void => {
  trackEvent({
    event: 'newPage',
    vpvPath: path,
    siteVersion: packageDetails.version,
    // Explicitly flush other variables
    eventCategory: undefined,
    eventAction: undefined,
    eventLabel: undefined,
    isException: undefined,
  });
};

export const trackButtonClick = (style: string, label: string) => (): void => {
  trackEvent({
    event: 'buttonClick',
    eventCategory: 'Button',
    eventAction: 'Click',
    eventLabel: `${style} - ${label}`,
  });
};

export const trackFooterClick = (linkText: string, linkUrl: string) => (): void => {
  trackEvent({
    event: 'footerClick',
    eventCategory: 'Footer Link',
    eventAction: 'Click',
    eventLabel: `${linkText} - ${linkUrl}`,
  });
};

export const trackAccordionExpandClick = (itemName: string) => (): void => {
  trackEvent({
    event: 'accordionClick',
    eventCategory: 'Accordion',
    eventAction: 'Expand',
    eventLabel: itemName,
  });
};

export const trackAccordionCollapseClick = (itemName: string) => (): void => {
  trackEvent({
    event: 'accordionClick',
    eventCategory: 'Accordion',
    eventAction: 'Collapse',
    eventLabel: itemName,
  });
};

export const trackMainNavigationClick = (
  eventLabel: string,
  eventAction: string
) => (): void => {
  trackEvent({
    event: 'navigationClick',
    eventCategory: 'Main Navigation',
    eventAction: `${eventAction}`,
    eventLabel: `${eventLabel}`,
  });
};

export const trackErrorPageVisit = (): void => {
  trackEvent({
    event: 'newError',
    eventCategory: 'Page error',
    eventAction: '404',
    eventLabel: window?.location?.pathname,
  });
};

enum ClickType {
  PLAINTEXT = 'Plaintext link',
  TELEPHONE = 'Telephone link',
  EMAIL = 'Email link',
}

const calculateClickType: (linkUrl: string) => ClickType = (linkUrl) => {
  if (linkUrl?.startsWith('tel:')) {
    return ClickType.TELEPHONE;
  }
  if (linkUrl?.startsWith('mailto:')) {
    return ClickType.EMAIL;
  }
  return ClickType.PLAINTEXT;
};

export const trackLinkClick: (label: string, url: string) => void = (label, url) => {
  trackEvent({
    event: 'linkClickText',
    eventCategory: 'Link click',
    eventAction: calculateClickType(url),
    eventLabel: label,
  });
};

export const trackDownloadClick: (url: string, fileType?: string) => void = (
  url,
  fileType
) => {
  trackEvent({
    event: 'download',
    eventCategory: 'Download',
    eventAction: fileType,
    eventLabel: url,
  });
};

export const trackNewDownloadClick: (
  pagePath: string,
  fileSize: number | null,
  fileName: string | undefined,
  fileText: string,
  fileUrl: string,
  label: string,
  element: string
) => void = (pagePath, fileSize, fileName, fileText, fileUrl, label, element) => {
  trackEvent({
    event: 'download',
    vpvPath: pagePath,
    downloadFileSize: fileSize,
    downloadFileName: fileName,
    downloadFileText: fileText,
    downloadFileUrl: fileUrl,
    ariaLabelText: label,
    linkElement: element,
  });
};

export const trackRadioButtonClick = (label: string, value: string): void => {
  trackEvent({
    event: 'radioButtonClick',
    eventCategory: 'Radio Button',
    eventAction: 'Select',
    eventLabel: `${label} - ${value}`,
  });
};

export const trackDropdownSelect = (label: string): void => {
  trackEvent({
    event: 'dropdownSelector',
    eventCategory: 'Selector Dropdown',
    eventAction: 'Select',
    eventLabel: label,
  });
};
